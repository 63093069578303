import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Input, InputGroup } from 'reactstrap';

const CustomPasswordInput = (props) => {

    const { name, id, onChange, value, className,loginForm, ...rest } = props;

    const [textInputType, setTextInputType] = useState(false);

    return (
        <>
            <InputGroup>
                <Input type={textInputType ? "text" : "password"} autoComplete="new-password" className={`input-password ${className || ""}`} name={name} value={value} onChange={onChange} id={id} {...rest} />
                <Button tabIndex={-1} className='eye-button' onClick={() => { setTextInputType((prevVal) => !prevVal) }}>
                    <FontAwesomeIcon color={loginForm ? "white" : ""} tabIndex={-1} icon={textInputType ? faEyeSlash : faEye} className={textInputType ? "show-password-text-icon" : ""} />
                </Button>
            </InputGroup>
        </>
    );
};

export default CustomPasswordInput;