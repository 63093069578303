import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import AppContent from "../../AppContent";

import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import { useAuthState } from "../../../context/login";
import { getRecentAssessments } from "../../../services/tprmSupportService";
import { ZammadTicketStatesMap } from "../../../utils/commonFns";
import { TableContainer } from "@mui/material";

export default function AllAssessments() {
  const [assessments, setAssessments] = useState([]);

  const { user } = useAuthState();

  useEffect(() => {
    const fetchRecentAssessments = async () => {
      const { result } = await getRecentAssessments(user.vendorId, true, null);
      setAssessments(result);
    };

    fetchRecentAssessments();
  }, []);

  let history = useHistory();

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle heading="All Assessments"></PageTitle>
              <Grid>
                <Card sx={{ height: "100%" }}>
                  <CardHeader title="All Assessments" />
                  <Divider />
                  <Box sx={{ overflowX: "auto" }}>
                    <TableContainer sx={{ maxHeight: "calc(100vh - 203px)" }}>
                      <Table sx={{ minWidth: 800 }} stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Assessment Number</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell sortDirection="desc">
                              Start Date
                            </TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {assessments.map((order) => {
                            const { label, color } = ZammadTicketStatesMap[
                              order.status
                            ] ?? {
                              label: order.status,
                              color: "default",
                            };

                            return (
                              <TableRow
                                hover
                                key={order.ticketNo}
                                onClick={() =>
                                  history.push(
                                    `/assessment-details/${order.id}`
                                  )
                                }
                              >
                                <TableCell>{order.ticketNo}</TableCell>
                                <TableCell>
                                  {order.businessOrganizationName}
                                </TableCell>
                                <TableCell>
                                  {dayjs(order.startDate).format("MMM D, YYYY")}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    color={color}
                                    label={label}
                                    size="small"
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Card>
              </Grid>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
