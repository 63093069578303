import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import AppContent from "../../AppContent";

import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { CardContent, TableContainer } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";

import Chart from "react-apexcharts";
import { useAuthState } from "../../../context/login";
import {
  getMontlyAssessments,
  getRecentAssessments,
} from "../../../services/tprmSupportService";
import {
  ZammadTicketStates,
  ZammadTicketStatesMap,
} from "../../../utils/commonFns";

export default function CompletedAssessments() {
  const [assessments, setAssessments] = useState([]);
  const [monthlyCompletedAssessments, setMonthlyCompletedAssessments] =
    useState([]);

  const { user } = useAuthState();

  useEffect(() => {
    const fetchRecentAssessments = async () => {
      const { result } = await getRecentAssessments(
        user.vendorId,
        true,
        ZammadTicketStates.Completed
      );
      setAssessments(result);
    };

    const fetchMonthlyAssessments = async () => {
      const { result } = await getMontlyAssessments(
        user.vendorId,
        ZammadTicketStates.Completed
      );
      setMonthlyCompletedAssessments(result);
    };

    fetchRecentAssessments();
    fetchMonthlyAssessments();
  }, []);

  const chartOptions = useChartOptions();

  let history = useHistory();

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle heading="Completed Assessments"></PageTitle>
              <Grid container spacing={3}>
                <Grid md={12} xs={12}>
                  <Card sx={{ height: "100%" }}>
                    <CardHeader title="Completion Trend" />
                    <CardContent>
                      <Chart
                        height={350}
                        options={chartOptions}
                        series={[
                          {
                            name: "Completed",
                            data: monthlyCompletedAssessments?.map(
                              (x) => x.completedCount
                            ),
                          },
                        ]}
                        type="line"
                        width="100%"
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid md={12} xs={12}>
                  <Card sx={{ height: "100%" }}>
                    <CardHeader title="Completed Assessments" />
                    <Divider />
                    <Box sx={{ overflowX: "auto" }}>
                      <TableContainer sx={{ maxHeight: 620 }}>
                        <Table stickyHeader sx={{ minWidth: 800 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Assessment Number</TableCell>
                              <TableCell>Customer</TableCell>
                              <TableCell sortDirection="desc">
                                Start Date
                              </TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {assessments.map((order) => {
                              const { label, color } = ZammadTicketStatesMap[
                                order.status
                              ] ?? {
                                label: order.status,
                                color: "default",
                              };

                              return (
                                <TableRow
                                  hover
                                  key={order.ticketNo}
                                  onClick={() =>
                                    history.push(
                                      `/assessment-details/${order.id}`
                                    )
                                  }
                                >
                                  <TableCell>{order.ticketNo}</TableCell>
                                  <TableCell>
                                    {order.businessOrganizationName}
                                  </TableCell>
                                  <TableCell>
                                    {dayjs(order.startDate).format(
                                      "MMM D, YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Chip
                                      color={color}
                                      label={label}
                                      size="small"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}

function useChartOptions() {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: { show: false },
    },
    colors: [
      theme.palette.secondary.main,
      alpha(theme.palette.secondary.main, 0.25),
    ],
    dataLabels: { enabled: false },
    fill: { opacity: 1, type: "solid" },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    legend: { show: false },
    stroke: { curve: "smooth" },
    theme: { mode: theme.palette.mode },
    xaxis: {
      axisBorder: { color: theme.palette.divider, show: true },
      axisTicks: { color: theme.palette.divider, show: true },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: { offsetY: 5, style: { colors: theme.palette.text.secondary } },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: { colors: theme.palette.text.secondary },
      },
    },
  };
}
