import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import AppContent from "../../AppContent";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { CardContent } from "@mui/material";
import {
  EnvelopeSimple,
  Infinity,
  FlagBanner,
  EnvelopeSimpleOpen,
  FlagBannerFold,
} from "@phosphor-icons/react";
import { hasValue } from "../../../utils/commonFns";
import { format } from "date-fns";
import "react-vertical-timeline-component/style.min.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { getAsssessmentData } from "../../../services/tprmSupportService";
import { useParams } from "react-router-dom";

export default function AssessmentDetails() {
  const queryParams = useParams();
  const assessmentId = queryParams["assessmentId"];

  const [assessmentData, setAssessmentData] = useState({});

  useEffect(() => {
    const fetchAssessmentData = async () => {
      const { result } = await getAsssessmentData(assessmentId);
      setAssessmentData(result);
    };

    fetchAssessmentData();
  }, []);

  const items = [
    {
      title: "Request Created",
      subTitle: hasValue(assessmentData?.createdOn)
        ? format(new Date(assessmentData?.createdOn), "PPP p")
        : "",
      completed: hasValue(assessmentData?.createdOn) ? true : false,
      reIcon: <EnvelopeSimple size={18} />,
      iconBackground: "#4CAF4F",
    },
    {
      title: "In progress",
      subTitle: hasValue(assessmentData?.inProgressOn)
        ? format(new Date(assessmentData?.inProgressOn), "PPP p")
        : "--",
      completed: hasValue(assessmentData?.inProgressOn) ? true : false,
      reIcon: <Infinity size={18} />,
      iconBackground: "#F44334",
    },
    {
      title: "Completed",
      subTitle: hasValue(assessmentData?.completedOn)
        ? format(new Date(assessmentData?.completedOn), "PPP p")
        : "--",
      completed: hasValue(assessmentData?.completedOn) ? true : false,
      reIcon: <FlagBanner />,
      iconBackground: "#1B72E8",
    },
  ];

  if (hasValue(assessmentData?.reOpenedOn)) {
    items.push(
      ...[
        {
          title: "Re opened",
          subTitle: hasValue(assessmentData?.reOpenedOn)
            ? format(new Date(assessmentData?.reOpenedOn), "PPP p")
            : "--",
          completed: hasValue(assessmentData?.reOpenedOn) ? true : false,
          reIcon: <EnvelopeSimpleOpen size={18} />,
          iconBackground: "rgb(251,140,0)",
        },
        {
          title: "Completed",
          subTitle: hasValue(assessmentData?.reCompletedOn)
            ? format(new Date(assessmentData?.reCompletedOn), "PPP p")
            : "--",
          completed: hasValue(assessmentData?.reCompletedOn) ? true : false,
          reIcon: <FlagBannerFold size={18} />,
          iconBackground: "rgb(233,30,98)",
        },
      ]
    );
  }

  return (
    <AppContent>
      <Fragment>
        <TransitionGroup>
          <CSSTransition
            component="div"
            className="TabsAnimation"
            appear={true}
            timeout={0}
            enter={false}
            exit={false}
          >
            <div>
              <PageTitle heading="Assessment Details"></PageTitle>

              <Card sx={{ height: "100%" }}>
                <CardHeader title="Assessment Timeline" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid md={1}></Grid>
                    <Grid md={4} xs={12}>
                      <VerticalTimeline layout="1-column-left">
                        {items.map((item, index) => {
                          return (
                            <VerticalTimelineElement
                              key={`${item.title}-${index}`}
                              className="vertical-timeline-element--work"
                              contentStyle={{
                                background: "#171811",
                                color: "#fff",
                              }}
                              contentArrowStyle={{
                                borderRight: "7px solid  #171811",
                              }}
                              iconStyle={{
                                background: item.iconBackground,
                                color: "#fff",
                                boxShadow:
                                  "0 0 0 4px #dcb9cc, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05)",
                              }}
                              icon={item.reIcon}
                            >
                              <h5 className="vertical-timeline-element-title">
                                {item.title}
                              </h5>
                              <p>{item.subTitle}</p>
                            </VerticalTimelineElement>
                          );
                        })}
                      </VerticalTimeline>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Fragment>
    </AppContent>
  );
}
