import axios from "axios";
import AppConfig from "../config";
import html2canvas from "html2canvas";

const baseURL = `${AppConfig.API_URL}/SendEmail`;

const sendEmail = (request) => {
    try {
        axios.post(`${baseURL}`, JSON.stringify(request));
    } catch (error) {
        console.log(error)
    }
};


const sendErrorMail = async (errorStr) => {

    const canvas = await html2canvas(document.body);

    try {
        axios.post(`${baseURL}/send-error-mail`, JSON.stringify({
            "subject": "Beaconer FE Errors",
            "errorEmailBodyHTML": errorStr,
            "attachmentBase64Srtring": canvas.toDataURL("image/png"),
        }))
    } catch (error) {
        
    }
}

export {
    sendEmail,
    sendErrorMail,
}