import React from "react";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useAuthDispatch } from "../../context/login";
import { hasValue } from "../../utils/commonFns";
import queryString from "query-string";

//trigger
const ImpersonateRedirect = (props) => {
  const dispatch = useAuthDispatch();

  const history = useHistory();
  const params = queryString.parse(props.location.search);

  useEffect(() => {
    if (hasValue(params?.response)) {
      const data = JSON.parse(atob(params?.response));
      localStorage.setItem("currentUser", JSON.stringify(data));
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      history.push("/assessments");
    }
  }, []);

  return (
    <div style={{ margin: "30px" }}>
      <PageTitle heading="Redirecting..."></PageTitle>
    </div>
  );
};

export default ImpersonateRedirect;
