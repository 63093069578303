export const VendorAssessmentsNav = [
  {
    icon: "pe-7s-albums",
    label: "My Assessments",
    to: "/vendor/assessments",
    internalRoutes: ["/assessment/"],
  },
];

export const ManageTeamNav = [
  {
    icon: "pe-7s-users",
    label: "Manage Team",
    to: "/vendor/users",
  },
];

export const RemediationNav = [
  {
    icon: "pe-7s-tools",
    label: "Remediations",
    to: "/vendor/remediations",
  },
];

export const DashboardNav = [
  {
    icon: "pe-7s-graph",
    label: "Dashboard",
    to: "/vendor/dashboard",
  },
];
