import React, { Fragment, useEffect, useState } from "react";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
} from "reactstrap";

import PasswordResetModal from "../../../pages/reset-password";

import { logout, useAuthDispatch, useAuthState } from "../../../context/login";
import { hasValue } from "../../../utils/commonFns";

const UserBox = (props) => {
  const dispatch = useAuthDispatch();
  const { user } = useAuthState();

  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const logOutme = () => {
    logout(dispatch);
  };

  useEffect(() => {
    if (!user.isUserResetPassword && !hasValue(user.impersonateUserId)) {
      setIsPasswordResetModalOpen(true);
    }
  }, []);

  return (
    <Fragment>
      <div className="header-btn-lg pe-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            {/* <div className="widget-content-left  me-2 header-user-info">
                            <div className="widget-heading">
                                {`${user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)} ${user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}`}
                            </div>
                            <div className="widget-subheading" style={{ textAlign: 'right' }}>
                                {user.roleDesc}
                            </div>
                        </div> */}
            <div className="widget-content-left">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle color="link" className="p-0 user-icon-toggle">
                  {/* <img width={36} className="rounded-circle" src={user1} alt="" /> */}
                  <div
                    data-initials={`${user.firstName
                      .charAt(0)
                      .toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`}
                  >
                    {/* <FontAwesomeIcon className="opacity-8 caret-down-icon" icon={faAngleDown} /> */}
                  </div>
                </DropdownToggle>
                <DropdownMenu end className="rm-pointers">
                  <Nav vertical>
                    <NavItem>
                      <NavLink href="#" className="name-heading">
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <p>
                            {`${
                              user.firstName.charAt(0).toUpperCase() +
                              user.firstName.slice(1)
                            } ${
                              user.lastName.charAt(0).toUpperCase() +
                              user.lastName.slice(1)
                            }`}
                          </p>
                          {user.impersonateUserId > 0 && (
                            <>
                              <p
                                style={{ marginBottom: "0px" }}
                              >{`Impersonate by`}</p>
                              <p
                                style={{ margin: "0px" }}
                              >{`${user.impersonateUserFullName}`}</p>
                            </>
                          )}
                        </div>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        onClick={() => {
                          toggle();
                          setIsPasswordResetModalOpen(true);
                        }}
                      >
                        Reset Password
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#" onClick={logOutme}>
                        Logout
                      </NavLink>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </Dropdown>
            </div>

            <PasswordResetModal
              {...{ isPasswordResetModalOpen, setIsPasswordResetModalOpen }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
