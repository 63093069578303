import React, {  Fragment } from 'react';
import cx from 'classnames';

import Nav from '../AppNav/VerticalNavWrapper';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

// import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';

// import {
//     setEnableMobileMenu
// } from '../../reducers/ThemeOptions';

import { ThemeActions, useThemeDispatch, useThemeState } from '../../context/theme';

const AppSidebar = (props) => {

    let {
        backgroundColor,
        // enableBackgroundImage,
        enableSidebarShadow,
        // backgroundImage,
        // backgroundImageOpacity,
        enableMobileMenu
    } = useThemeState();

    const dispatch = useThemeDispatch();

    const toggleMobileSidebar = () => {
        ThemeActions.SET_ENABLE_MOBILE_MENU(dispatch, { enableMobileMenu: !enableMobileMenu });
    }



    return (
        <Fragment>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    className={cx("app-sidebar", backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
                    appear={true} timeout={1500} enter={false} exit={false}>
                    <div>
                        <HeaderLogo />
                        <div className="app-sidebar__inner">
                            <Nav />
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )

}

export default AppSidebar;