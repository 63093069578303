import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import './assets/base.css';
import './assets/template-override.css';
import './pages/Login/login.css';
import 'react-toastify/dist/ReactToastify.css';
import Main from './pages/Main';
import { AuthProvider } from './context/login';
import { ThemeProvider } from './context/theme';
import './utils/interceptor';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ErrorBoundary from './utils/ErrorBoundary';

const rootElement = document.getElementById('root');

const renderApp = Component => {
  ReactDOM.render(
    <ErrorBoundary>
      <AuthProvider>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter basename={'/vendor'}>
              <Component />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </ErrorBoundary>,
    rootElement
  );
};

renderApp(Main);

unregister();

registerServiceWorker();

