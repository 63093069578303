import React from 'react';

const PageTitle = (props) => {
    const {
        heading,
        PageTitleActions,
        BreadcrumbComp
    } = props;

    return (

        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div>

                    {BreadcrumbComp != null && (
                        <div className='page-title-breadcrumbs'>
                            {BreadcrumbComp}
                        </div>
                    )}
                    <div className="page-title-heading">
                        {heading}
                    </div>

                </div>
                {
                    PageTitleActions != null && (
                        <div className="page-title-actions">
                            {PageTitleActions}
                        </div>
                    )
                }
            </div>
        </div>
    );

}

export default PageTitle;