import { Route, Redirect, Switch } from "react-router-dom";
import React, { Suspense, Fragment } from "react";
import { useAuthState } from "../../context/login";

import { ToastContainer } from "react-toastify";
import Remediations from "../../pages/remediations";
import RemediationDetails from "../../pages/remediations/RemediationDetails";
import CompletedAssessments from "../../pages/dashboard/views/completed";
import AssessmentDetails from "../../pages/dashboard/views/details";
import AllAssessments from "../../pages/dashboard/views/list";
import ImpersonateRedirect from "../../pages/redirect";

const VendorAssessments = React.lazy(() =>
  import("../../pages/vendor-assessments")
);
const Dashboard = React.lazy(() => import("../../pages/dashboard"));
const Questionnaire = React.lazy(() => import("../../pages/questionnaire"));
const Documentary = React.lazy(() => import("../../pages/documentary"));
const Login = React.lazy(() => import("../../pages/Login"));
const Assessment = React.lazy(() =>
  import("../../pages/vendor-assessments/assessment")
);
const ManageTeam = React.lazy(() => import("../../pages/manage-team"));
const SessionIdle = React.lazy(() => import("../../pages/session"));

const AppMain = () => {
  return (
    <Fragment>
      <Suspense fallback={<div id="loader"></div>}>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route
            path="/dashboard"
            render={() => (
              <ProtectedComponent>
                <Dashboard />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/completed-assessments"
            render={(props) => (
              <ProtectedComponent>
                <CompletedAssessments {...props} />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/all-assessments"
            render={(props) => (
              <ProtectedComponent>
                <AllAssessments {...props} />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/assessment-details/:assessmentId"
            render={(props) => (
              <ProtectedComponent>
                <AssessmentDetails {...props} />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/assessments"
            render={() => (
              <ProtectedComponent>
                <VendorAssessments />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/assessment/:assessmentId/questionnaire/:id"
            render={(props) => (
              <ProtectedComponent>
                <Questionnaire {...props} />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/assessment/:assessmentId/documentary/:id"
            render={(props) => (
              <ProtectedComponent>
                <Documentary {...props} />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/assessment/:id"
            render={(props) => (
              <ProtectedComponent>
                <Assessment {...props} />
              </ProtectedComponent>
            )}
          />

          <Route
            path="/remediations"
            render={(props) => (
              <ProtectedComponent>
                <Remediations {...props} />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/remediation/:id"
            render={(props) => (
              <ProtectedComponent>
                <RemediationDetails {...props} />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/users"
            render={(props) => (
              <ProtectedComponent>
                <ManageTeam {...props} />
              </ProtectedComponent>
            )}
          />
          <Route
            path="/redirect"
            render={(props) => <ImpersonateRedirect {...props} />}
          />
          <Route path="*" render={() => <Redirect to="/assessments" />} />
        </Switch>
        <SessionIdle />
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div id="loader"></div>
    </Fragment>
  );
};

const ProtectedComponent = ({ children }) => {
  const userDetails = useAuthState();
  return !Boolean(userDetails.token) ? <Redirect to="/" /> : <>{children}</>;
};

export default AppMain;
