/**
 * src/RouterLink.jsx
 * Author: H.Alper Tuna <halpertuna@gmail.com>
 * Date: 08.09.2016
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

const RouterLink = (props) => {

    const history = useHistory();

    useEffect(() => {
        history.listen((e) => {
            if ((e.pathname || '/') === props.to) {
                props.activateMe();
            }
        });
    }, [history, props.to])


    const {
        className,
        classNameActive,
        classNameHasActiveChild,
        active,
        hasActiveChild,
        externalLink,
        hasSubMenu,
        toggleSubMenu,
        children,
    } = props;

    let to = props?.to;
    to = to?.split("/").splice(2).join("/");
    if (to !== '/') to = `/${to}`;

    return (
        hasSubMenu || externalLink
            ? (
                <a
                    className={classnames(
                        className,
                        hasActiveChild && classNameHasActiveChild
                    )}
                    target={externalLink ? '_blank' : undefined}
                    href={to}
                    onClick={toggleSubMenu} rel="noreferrer"
                >
                    {children}
                </a>
            )
            : (
                <Link
                    className={classnames(
                        className,
                        active && classNameActive
                    )}
                    to={to}
                >
                    {children}
                </Link>
            )
    );
}

RouterLink.propTypes = {
    className: PropTypes.string.isRequired,
    classNameActive: PropTypes.string.isRequired,
    classNameHasActiveChild: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    hasActiveChild: PropTypes.bool.isRequired,
    to: PropTypes.string.isRequired,
    externalLink: PropTypes.bool,
    hasSubMenu: PropTypes.bool.isRequired,
    toggleSubMenu: PropTypes.func,
    activateMe: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ]).isRequired,
};

RouterLink.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default RouterLink;