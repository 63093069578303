import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Link from "./RouterLink";
import MetisMenu from "react-metismenu";

import {
  VendorAssessmentsNav,
  ManageTeamNav,
  RemediationNav,
  DashboardNav,
} from "./NavItems";

const Nav = (props) => {
  const location = useLocation();

  // const isPathActive = (path) => {
  //     return location.pathname.startsWith(path);
  // }

  const [activeMenu, setActiveMenu] = useState(
    Math.random().toString().slice(2)
  );

  useEffect(() => {
    if (
      VendorAssessmentsNav[0].internalRoutes.some(
        (x) => location.pathname.indexOf(x) > -1
      )
    ) {
      setActiveMenu("active");
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <MetisMenu
        LinkComponent={Link}
        content={DashboardNav}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <MetisMenu
        LinkComponent={Link}
        content={VendorAssessmentsNav}
        activeLinkFromLocation
        classNameLink={activeMenu}
        key={activeMenu}
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <MetisMenu
        LinkComponent={Link}
        content={RemediationNav}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />

      <MetisMenu
        LinkComponent={Link}
        content={ManageTeamNav}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
    </Fragment>
  );
};

export default Nav;
