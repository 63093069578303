import { Input } from "reactstrap";
import { toast } from "react-toastify";

export const hasValue = (val) => {
  return val !== null && val !== undefined && val.toString().trim().length > 0;
};

export const RequestType = {
  Questionnaire: "Questionnaire",
  DocumentRequest: "Document request",
};

export const ResponseType = {
  Yes: "Y",
  No: "N",
  NA: "NA",
};

export const SubmissionStatus = {
  SubmittedToVendor: "Submitted To Vendor",
  ResponseReceived: "Response Received",
  Accepted: "Accepted",
  ReturnToVendor: "Return To Vendor",
};

export const MUIDataGridTheme = {
  "& .disabled-row": {
    backgroundColor: "#eff7f1",
  },
};

export const CustomCheckBox = (props) => (
  <Input className="custom-check-box" type="checkbox" {...props} />
);

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

export const checkPasswordValidations = (newPassword, confirmPassword) => {
  if (!hasValue(newPassword)) {
    toast("Please Enter New Password", {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }

  if (!hasValue(confirmPassword)) {
    toast("Please Enter Confirm Password", {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }

  if (newPassword !== confirmPassword) {
    toast("New Password and Confirm Password does not match", {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }

  if (!passwordRegex.test(newPassword)) {
    let ToastContent = () => (
      <>
        <b>New Password should meet following criteria.</b>
        <p>1. Should contain Min. 6 - Max. 16 characters</p>
        <p>2. Should contain atleast 1 number</p>
        <p>
          3. Should contain atleast 1 special character {" ! @ # $ % ^ & *"}
        </p>
      </>
    );
    toast(<ToastContent />, {
      autoClose: false,
      type: toast.TYPE.ERROR,
      position: toast.POSITION.TOP_CENTER,
    });
    return false;
  }

  return true;
};

export const ZammadTicketStates = {
  New: "New",
  InProgress: "In progress",
  PendingWithBusiness: "Pending with Business",
  Completed: "Completed",
  Reopened: "Reopened",
  Approved: "Approved",
};

export const ZammadTicketStatesMap = {
  New: { label: "New", color: "success" },
  "In progress": { label: "In progress", color: "warning" },
  "Pending with Business": { label: "In progress", color: "warning" },
  Completed: { label: "Completed", color: "success" },
  Reopened: { label: "In progress", color: "warning" },
  Approved: { label: "In progress", color: "warning" },
};
